import React, { useState, useEffect } from "react";
import momentTZ from "moment-timezone";
import { useMercuryContext, useMercuryDispatch } from "../../user-context";
import { failedNotification } from "../../utils/notifications";
import { Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import "./SetTimezone.scss";

interface Timezone {
  value: string;
  time: string;
}

const SetTimezone: React.FC = () => {
  const mercuryDispatch = useMercuryDispatch();
  const { Option } = Select;

  const [isActive, setIsActive] = useState<boolean>(false);
  const [timezones, setTimezones] = useState<Timezone[]>([]);

  const { timezone } = useMercuryContext();

  // Fetch and format all timezones
  const getTimezones = async () => {
    let timezones: string[] = [];
    try {
      timezones = momentTZ.tz.names();
    } catch (e) {
      console.error(e);
      failedNotification("Error", "Failed to retrieve timezones.");
      return;
    }

    const formattedTimezones: Timezone[] = timezones.map((item) => ({
      value: item,
      time: momentTZ.tz(item).format("Z"),
    }));

    const sortedTimezones = formattedTimezones.sort(
      (a, b) =>
        parseFloat(a.time.replace(":", ".")) -
        parseFloat(b.time.replace(":", "."))
    );

    setTimezones(sortedTimezones);
  };

  useEffect(() => {
    getTimezones();
  }, [isActive]);

  const timezoneMenu = timezones.map((item, index) => (
    <Option value={item.value} key={index}>
      {`(GMT ${item.time}), ${item.value.replaceAll("_", " ")}`}
    </Option>
  ));

  const handleChange = (item: string) => {
    mercuryDispatch({ type: "UPDATE_TIMEZONE", payload: item });
    localStorage.setItem("timezone", item);
    setIsActive(false);
  };

  return (
    <div
      className={
        isActive
          ? "timezone-menu-wrap timezone-menu-active"
          : "timezone-menu-wrap"
      }
    >
      <GlobalOutlined />
      <Select
        value={timezone}
        onChange={handleChange}
        onDropdownVisibleChange={() => setIsActive(!isActive)}
        onBlur={() => setIsActive(false)}
        showSearch
        bordered={false}
        style={{
          width: "100%",
          caretColor: !isActive ? "transparent" : undefined,
        }}
        className="select-timezone-dropdown"
        dropdownStyle={{ minWidth: "240px" }}
        dropdownAlign={{ offset: [-18, 4] }}
      >
        {timezoneMenu}
      </Select>
    </div>
  );
};

export default SetTimezone;
