import React, {useState} from "react";
import {Button, Modal, Tooltip} from "antd";
import {failedNotification, successfulNotification} from "../../utils/notifications";
import {useMercuryContext} from "../../user-context";

const RecoverProject = ( { selectedRows, reload, page }: any) => {

  const context = useMercuryContext();
  const { gpClient } = context;
  const [ visible, setVisible ] = useState( false );
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function onSubmit() {
    try {

      let recoveredProjectPromises = [];
      recoveredProjectPromises = selectedRows.map((project: any) => {
        return gpClient.delete(`/google-gp-v1/project/recover/${project._id}`)
          .then((response:any) => {
            const { recoveredPLPs } = response.data;

            successfulNotification(
              `Project with ID: ${project.shortId} has been recovered`,
              `${recoveredPLPs} recovered PLPs`
            );
          })
          .catch((error:any) => {
            if (error.response) {
              failedNotification(
                `Failed to recover the project: ${project.shortId}`,
                error.response.data.detail
              );
            } else {
              failedNotification("Failed to recover the project(s)");
            }
          });
      });

      await Promise.allSettled(recoveredProjectPromises);

    } catch (error) {
      failedNotification("Failed to Recover the Project(s)");
    }
  }

  let oneDeletedProject = selectedRows.find( ({ deleted }: any) => deleted === true );
  if ( (selectedRows.length !== 0) && oneDeletedProject ) {
   return (
    <>
      <Button
        onClick={() => setVisible( !visible )}
        className="action-btn edit-btn"
        icon=""
        type="primary"
      >
        Recover Project(s)
      </Button>
      <Modal
        open={visible}
        title="Project Recovery"
        okText="OK"
        cancelText="Cancel"
        onCancel={ () => {
          setVisible( false );
          setConfirmLoading(false);
        }}
        onOk={async () => {
          try {
            setConfirmLoading(true);
            await onSubmit();
            setConfirmLoading(false);
            reload.call(page);
            setVisible(false);
          } catch (error) {
            console.error("Validate Failed:", error);
          }
        }}
        confirmLoading={confirmLoading}
      >
        <p>Would you like to recover the selected project(s)?</p>
      </Modal>
    </>
  );

}

  return (
    <Tooltip
      title="Select at least 1 row which contains deleted plps"
      placement="bottom"
      color={"var(--tertiary-color)"}
    >
      <Button
        disabled
        style={{ borderColor: "var(--tertiary-color)" }}
      >
        Recover Project(s)
      </Button>
    </Tooltip>
  )

};

export default RecoverProject;
