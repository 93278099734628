import React, { useEffect, useState, useCallback } from 'react';
import ExtProjectTable from './ExtProjectTable/ExtProjectTable';
import { postGExtensionRequests } from '../../api/extensionRequest';
import ExtensionDetails from '../ExtensionDetailsModal/ExtensionDetailsModal';
import { useMercuryContext } from '../../../../user-context';

/**
 * Table with extension requests
 * Also includes filters to select the requests
 * It is necessary to have the token into useContext
 * @returns ListExtension component
 */
export const ListExtByProject = (props) => {
  const { projectId } = props;
  const [reqExtElements, setReqExtElements] = useState([]);
  const initialFilterState = { shortId: [projectId] };
  const [filtersValues, setFiltersValues] = useState(initialFilterState);
  const [stateRequest, setStateRequest] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalExtensionId, setModalExtensionId] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 100;
  const [arrTokens, setArrTokens] = useState([]);
  const { accessToken } = useMercuryContext();

  /*
  Testing Dispatch
  const mercuryDispatch = useMercuryDispatch();

  const fillDispatch = () => {
    mercuryDispatch({ type: 'UPDATE_TOKEN', payload: '12345' });
  };
*/

  const handleGetExtTable = useCallback(
    async () => {
      try {
        setStateRequest(true);
        // Check the page and update token
        const nextToken = arrTokens[currentPage - 2];
        // Call the API
        postGExtensionRequests(
          filtersValues,
          currentPage,
          projectsPerPage,
          nextToken,
          accessToken,
          setStateRequest,
          reqExtElements,
        ).then((res) => {
          setReqExtElements(res.extensionRequests);
          if (currentPage > arrTokens.length) {
            const arrTokensNew = [...arrTokens, res.nextToken];
            setArrTokens(arrTokensNew);
          }
        });
      } catch (error) {
        console.error(error);
      }
      setStateRequest(false);
    },
    [accessToken, arrTokens, currentPage, filtersValues, reqExtElements]
  );

  useEffect(() => {
    // Call the API each time the filters, page or number of projects are changed 
      handleGetExtTable();
  }, []);

  const handleExtensionIdClick = (value) => {
    setModalExtensionId(value);
    setIsModalVisible(true);
  };
  return (
    <div className="dashboard-extensions list-request">
      {/*testing && (
        <input type="button" onClick={() => fillDispatch()} value="Add access token" />
      )*/}

      <ExtProjectTable
        reqExtElements={reqExtElements}
        stateRequest={stateRequest}
        filtersValues={filtersValues}
        setFiltersValues={setFiltersValues}
        handleExtensionIdClick={handleExtensionIdClick}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        arrTokens={arrTokens}
        projectsPerPage={projectsPerPage}
      />

      <ExtensionDetails
        modalExtensionId={modalExtensionId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};
