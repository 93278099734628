import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Space,
  Form,
  Select,
  Tag,
  Button,
  Modal,
} from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";

const CustomTagRender = ({ label, closable, onClose }: any) => {
  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="var(--tertiary-color)"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3, marginBottom: 3 }}
    >
      {label}
    </Tag>
  );
};

const EntityCard = ({
  form,
  title,
  options,
  findReplaceRequired = true,
  formFieldsPrefix,
  placeholder,
  showAreaField = false,
  productAreas,
}: any) => {
  const [radioValue, setRadioValue] = useState("addRemove");
  const [selectedAddOptions, setSelectedAddOptions] = useState<any>([]);
  const [selectedRemoveOptions, setSelectedRemoveOptions] = useState<any>([]);
  const [selectProductAreaCode, setSelectProductAreaCode] = useState<any>(null);
  const [showAreaModal, setShowAreaModal] = useState(false);

  const formattedOptions = showAreaField
    ? options.map((product: any) => ({
        value: product?.title,
        label: product?.title,
        areaid: product?.productArea?._id,
        areaname: product?.productArea?.title,
      }))
    : options.map((product: any) => ({
        value: product?.title,
        label: product?.title,
      }));

  const filteredAddOptions = formattedOptions.filter(
    (option: any) => !selectedRemoveOptions.includes(option.value)
  );

  const filteredRemoveOptions = formattedOptions.filter(
    (option: any) => !selectedAddOptions.includes(option.value)
  );

  const addProductsToSelect = () => {
    const productAreaId = form.getFieldValue("productArea");
    if (productAreaId && selectProductAreaCode) {
      const selectedProducts = form.getFieldValue(
        `products_${selectProductAreaCode}`
      );
      for (const product of options) {
        if (
          product.productArea._id === productAreaId &&
          !selectedProducts.includes(product.title)
        ) {
          selectedProducts.push(product.title);
        }
      }
      form.setFieldsValue({
        [`products_${selectProductAreaCode}`]: selectedProducts,
      });
      form.validateFields([`products_${selectProductAreaCode}`]);
    }
    setShowAreaModal(false);
    setSelectProductAreaCode(null);
    form.setFieldsValue({ productArea: null });
  };

  const renderAreaButton = (type: string) =>
    showAreaField && (
      <Col span={3}>
        <Button
          type="dashed"
          icon={<DoubleLeftOutlined />}
          style={{ width: "100%" }}
          onClick={() => {
            setSelectProductAreaCode(type);
            setShowAreaModal(true);
          }}
        >
          Area
        </Button>
      </Col>
    );

  return (
    <>
      <Row gutter={8}>
        <Col span={4}>{title}</Col>
        <Col span={20}>
          <Card
            cover={
              <Radio.Group
                value={radioValue}
                buttonStyle="solid"
                onChange={(ev) => setRadioValue(ev.target.value)}
              >
                <Radio.Button
                  style={{ width: "33%", textAlign: "center" }}
                  value="addRemove"
                >
                  Add / Remove
                </Radio.Button>
                <Radio.Button
                  style={{ width: "34%", textAlign: "center" }}
                  value="findReplace"
                >
                  Find / Replace
                </Radio.Button>
                <Radio.Button
                  style={{ width: "33%", textAlign: "center" }}
                  value="overwrite"
                >
                  Overwrite
                </Radio.Button>
              </Radio.Group>
            }
          >
            {radioValue === "addRemove" && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={8}>
                  <Col span={3}>Add</Col>
                  <Col span={showAreaField ? 18 : 21}>
                    <Form.Item name={`${formFieldsPrefix}_add`} noStyle>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder={`Add ${placeholder}`}
                        options={filteredAddOptions}
                        filterOption={(inputValue: any, option: any) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onChange={(value) => setSelectedAddOptions(value)}
                        tagRender={CustomTagRender}
                      />
                    </Form.Item>
                  </Col>
                  {renderAreaButton("add")}
                </Row>
                <Row gutter={8}>
                  <Col span={3}>Remove</Col>
                  <Col span={showAreaField ? 18 : 21}>
                    <Form.Item name={`${formFieldsPrefix}_remove`} noStyle>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder={`Remove ${placeholder}`}
                        options={filteredRemoveOptions}
                        filterOption={(inputValue: any, option: any) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onChange={(value) => setSelectedRemoveOptions(value)}
                        tagRender={CustomTagRender}
                      />
                    </Form.Item>
                  </Col>
                  {renderAreaButton("remove")}
                </Row>
              </Space>
            )}
            {radioValue === "findReplace" && (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={8}>
                  <Col span={3}>Find</Col>
                  <Col span={21}>
                    <Form.Item
                      name={`${formFieldsPrefix}_find`}
                      rules={[
                        {
                          required: findReplaceRequired,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder={`Find ${placeholder}`}
                        options={showAreaField ? formattedOptions : options}
                        allowClear
                        filterOption={(inputValue: any, option: any) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={3}>Replace</Col>
                  <Col span={showAreaField ? 18 : 21}>
                    <Form.Item
                      name={`${formFieldsPrefix}_replace`}
                      rules={[
                        {
                          required: findReplaceRequired,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder={`Replace ${placeholder}`}
                        options={showAreaField ? formattedOptions : options}
                        filterOption={(inputValue: any, option: any) =>
                          option.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        tagRender={CustomTagRender}
                      />
                    </Form.Item>
                  </Col>
                  {renderAreaButton("replace")}
                </Row>
              </Space>
            )}
            {radioValue === "overwrite" && (
              <Row gutter={8}>
                <Col span={3}>Overwrite</Col>
                <Col span={showAreaField ? 18 : 21}>
                  <Form.Item
                    name={`${formFieldsPrefix}_overwrite`}
                    rules={[
                      {
                        required: true,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder={`Overwrite ${placeholder}`}
                      options={showAreaField ? formattedOptions : options}
                      filterOption={(inputValue: any, option: any) =>
                        option.label
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      tagRender={CustomTagRender}
                    />
                  </Form.Item>
                </Col>
                {renderAreaButton("overwrite")}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      {showAreaModal && (
        <Modal
          title={`Select ${title}`}
          footer={null}
          open={showAreaField}
          onCancel={() => {
            setShowAreaModal(false);
            setSelectProductAreaCode(null);
          }}
        >
          <Row gutter={8}>
            <Col span={20}>
              <Form.Item name="productArea" label="Product Area">
                <Select
                  placeholder="Product Area"
                  style={{ width: "100%" }}
                  options={productAreas}
                  showSearch
                  filterOption={(inputValue: any, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={addProductsToSelect}>
                Select
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default EntityCard;
