import {
    DateFilter,
    ListFilter,
    BooleanFilter,
    TextFilter,
    CustomTooltip
} from "../../Components";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {BlockOutlined} from "@ant-design/icons";
import TimezoneConverter from "../../timezone-converter";
import UrgencyFilter from "../Urgency.filter";
import ProductAreaFilter from "../ProductArea.filter";
import ProjectTypeFilter from "../ProjectType.filter";
import ComponentTypeFilter from "../ComponentType.filter";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {DateTime} from "luxon";
import VarStatusFilter from "../VarStatus.filter";
import NotFoundPage from "../../routes/NotFoundPage";
import TableWSAbstract from "../../stories/Table/TableWS.abstract";
import {TableProps} from "antd/lib/table";
import {TableState} from "../../stories/Table/TableBase";
import uuid from "uuid-browser";
import InvoicingDetailsPage from "../../routes/InvoicingDetailsPage";
import InvoiceButton from "./InvoiceButton";
import AssignInvoiceNumberButton from "./AssignInvoiceNumberButton";
import AccrualButton from "./AccrualButton";
import EstimateButton from "./EstimateButton";
import RecoverProjectButton from "./RecoverProjectButton";
import BatchHours from "../../Search/Batch.hours";
import { Flex } from "antd";
import { productFilter } from '../../Search/filtersConfig';
import { productColumn, invoiceMonthColumn } from "../columnsConfig";
import EmpowerCCFilter from "../EmpowerCC.filter";

function TableCell( props: any ) {

    const
        rowData = props.rowData,
        text = rowData[props.column.dataIndex],
        record = rowData;

    if ( props.render ) {
        return props.render(text, record);
    }

    return text;
}

class InvoicingSearch extends TableWSAbstract<any> {

    pageTitle = "Invoicing - Mercury";
    title = "Invoicing";
    pageClass = "InvoicingTable"
    defaultSortField = "importedTimestamp";

    projectFilters = [
        {
            id: "projectId",
            name: "shortId",
            title: "Project ID",
            component: ListFilter,
            active: true
        },
        {
            id: "receivedDate",
            name: "importedTimestamp",
            title: "Received Date",
            component: DateFilter,
            active: true
        },
            productFilter,
        {
            id: "urgency",
            name: "turnaroundTimeUrgency",
            title: "Urgency",
            component: UrgencyFilter,
            active: true
        },
        {
            id: "productArea",
            name: "productArea",
            title: "Product Area",
            component: ProductAreaFilter,
            active: true
        },
        {
            id: "projectType",
            name: "projectType",
            title: "Project Type",
            component: ProjectTypeFilter,
            active: false
        },
        {
            id: "componentType",
            name: "componentType",
            title: "Component Type",
            component: ComponentTypeFilter,
            active: false
        },
        {
            id: "empowerCC",
            name: "costCode",
            title: "Empower CC",
            component: EmpowerCCFilter,
            active: false
        },
    ]

    invoicingFilters = [
        {
            id: "invoiceName",
            name: "invoiceName",
            title: "Invoice name",
            component: TextFilter
        },
        {
            id: "invoiceMonth",
            title: "Invoice Month",
            name: "invoiceMonth",
            component: DateFilter,
            active: false
        },
        {
            id: "varStatus",
            name: "varStatus",
            title: "Invoice status",
            component: VarStatusFilter,
            active: false
        },
        {
            id: "deleted",
            name: "deleted",
            title: "Deleted Projects",
            component: BooleanFilter
        },
    ];

    FilterComponents = [
        ...this.projectFilters,
        ...this.invoicingFilters
    ];

    defaultColumns = [
        {
            id: "title",
            title: "Title",
            dataIndex: "title",
            sorter: true,
            width: 400,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={57}/>,
        },
        productColumn,
        {
            id: "component",
            title: "Component Type",
            dataIndex: "componentType",
            sorter: true,
            width: 145,
            fixed: undefined,
        },
        {
            id: "urgency",
            title: "Urgency",
            dataIndex: "turnaroundTimeUrgency",
            sorter: true,
            width: 100,
            fixed: undefined,
        },
        {
            id: "projectTypes",
            title: "Project Type",
            dataIndex: "projectType",
            sorter: true,
            width: 110,
            fixed: undefined,
        },
        {
            id: "receivedDate",
            title: "Received Date",
            dataIndex: "importedTimestamp",
            sorter: true,
            width: 150,
            render: (text: string) => <TimezoneConverter date={text}/>,
            fixed: undefined,
        },
        invoiceMonthColumn,
        {
            id: "invoiceName",
            name: "invoiceName",
            title: "Invoice Name",
            dataIndex: "invoiceName",
            width: 130,
            sorter: true,
            render: ( text:string ) => {
                return text ? <CustomTooltip text={text} length={15}/> : <i className={"muted"}>Not set</i>
            },
            fixed: undefined,
        },
        {
            id: "varStatus",
            name: "varStatus",
            title: "Invoice status",
            dataIndex: "varStatus",
            fixed: undefined,
            width: 120,
            sorter: true
        },
        {
            id: "costCode",
            name: "costCode",
            title: "Empower CC",
            dataIndex: "costCode",
            width: 130,
            sorter: true,
            fixed: undefined,
        },
    ]
    
    CellRenderer= TableCell;
    columns = [
        {
            id: "projectId",
            title: "Project ID",
            dataIndex: "shortId",
            sorter: true,
            width: 140,
            fixed: undefined,
            render: (id: string, record: any) =>
            record.catToolProjectId !== null && (
              <>
              {
                record.deleted ?
                <Link
                  to="#"
                  id={id}
                  className={"project-link"}
                >
                  <BlockOutlined className="project-link-icon" style={{marginRight:'3px'}} />
                </Link>
                :
                <a
                  href={`/invoicing-project/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BlockOutlined className="project-link-icon" style={{marginRight:'3px'}} />
                </a>
              }
              {
                !record.deleted ?
                <Link
                  to={`/invoicing-project/${id}`}
                  id={id}
                  className={"project-link"}
                >
                  {id}
                </Link>
                :
                <Link
                  to="#"
                  id={id}
                  className={"project-link"}
                >
                  {id}
                </Link>
              }
              </>
            ),
        },
        ...this.defaultColumns,
  
    ] as any;

    renderActions() {
        return (
            <Flex wrap="wrap" gap="small">
                <RecoverProjectButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} />
                {this.props.context.flags.bulkSubmissionButton && <EstimateButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} />}
                {this.props.context.flags.bulkSubmissionButton && <AccrualButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} />}
                <AssignInvoiceNumberButton selectedRows={this.state.selectedRows} reload={() => this.getData()} />
                <BatchHours
                    selectedRows={this.state.selectedRows}
                    reload={this.getData}
                    bust={this.bustCache}
                    contentType={'invoice'}
                />
                <InvoiceButton selectedRows={this.state.selectedRows} reload={this.getData} page={this} />
            </Flex>
        );
    }

    rowClassName = (record: any, index: number): string => {
        const validStatuses = new Set([
            "NEW",
            "IN_PREPROCESSING",
            "PREPROCESSING_COMPLETE",
            "PENDING",
            "IN_TRANSLATION",
            "IN_PROOFREADING",
            "SIGN_OFF_WAITING",
            "IN_COPY_EDIT"
        ]);
    
        if (!validStatuses.has(record.catToolStatus)) {
            return this.state.changedIds?.has(record._id) ? "rowUpdated" : "";
        }
    
        const now = DateTime.now();
        const dueDate = DateTime.fromISO(record.dueDate);
        const SoD = dueDate.startOf('day');
        const EoD = dueDate.endOf('day');
    
        let className = "";
        if (now >= SoD && now <= EoD) {
            className = "metaRow warning";
        } else if (now >= dueDate) {
            className = "metaRow critical";
        }
    
        return `${className} ${this.state.changedIds?.has(record._id) ? "rowUpdated" : ""}`;
    };
    

    public rowMap:Map<string, any> = new Map();

    public floodPrevention = () => {
        this.clearFloodTimer();
        this.updateStateWithRowData();
    }
    
    clearFloodTimer = () => {
        clearTimeout(this.floodTimer);
        this.floodTimer = undefined;
    }
    
    updateStateWithRowData = () => {
        const rowData = Array.from(this.rowMap.values());
        const { changedIds } = this.state;
        this.setState({ rowData, changedIds });
    }

    public floodTimer?:number;


    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        this.setupSocketListeners();
    }
    
    setupSocketListeners() {
        const socket = this.props.context.gpSocket.project;
        socket.on("tail", this.handleTailEvent);
    }
    
    handleTailEvent = (doc: any) => {
        this.updateRowMap(doc);
        this.updateStateOnMount();
    }
    
    updateRowMap(doc: any) {
        this.rowMap.set(doc._id, doc);
        this.state.changedIds?.add(doc._id);
    
        const { rowData } = this.state;
        rowData.unshift(doc);
    
        this.rowMap.clear();
        rowData.forEach((row: any) => this.rowMap.set(row._id, row));
    }
    
    updateStateOnMount() {
        this.setState({
            rowData: Array.from(this.rowMap.values()),
            changedIds: this.state.changedIds
        });
    }

    componentDidUpdate(prevProps: TableProps<any>, prevState: TableState<any>) {
        super.componentDidUpdate(prevProps as any, prevState);
        if (prevState.reqId !== this.state.reqId) {
            this.updateSocketListeners(prevState.reqId);
        }
    }
    
    updateSocketListeners(prevReqId: any) {
        const socket = this.props.context.gpSocket.project;
        this.removeOldSocketListeners(socket, prevReqId);
        this.addNewSocketListeners(socket);
    }
    
    removeOldSocketListeners(socket: any, prevReqId: string) {
        socket.off(`search:row:${prevReqId}`);
        socket.off(`search:meta:${prevReqId}`);
    }
    
    addNewSocketListeners(socket: any) {
        socket.on(`search:row:${this.state.reqId}`, this.handleSearchRow);
        socket.on(`search:meta:${this.state.reqId}`, this.handleSearchMeta);
    }
    
    handleSearchRow = (row: any) => {
        const rows = Array.isArray(row) ? row : [row];
        rows.forEach(this.processRow);
        this.setState({
            loading: false,
            changedIds: new Set(),
            rowData: Array.from(this.rowMap.values())
        });
    }
    
    processRow = (item: any) => {
        const socket = this.props.context.gpSocket.project;
        this.rowMap.set(item._id, item);
        socket.on(item._id, this.handleDocUpdate);
    }
    
    handleDocUpdate = (doc: any) => {
        this.state.changedIds?.add(doc._id);
        if (this.rowMap.has(doc._id)) {
            this.rowMap.set(doc._id, doc);
            this.resetFloodTimer();
        }
    }
    
    resetFloodTimer() {
        if (this.floodTimer) {
            clearTimeout(this.floodTimer);
            this.floodTimer = undefined;
        }
        this.floodTimer = setTimeout(() => this.floodPrevention(), 500) as any;
    }
    
    handleSearchMeta = (meta: any) => {
        this.setState({
            selectedRows: [],
            currentPage: meta.page,
            meta: meta
        });
    }
    

    componentWillUnmount() {
        const socket = this.props.context.gpSocket.project;
        super.componentWillUnmount();
        socket.off( `search:row:${this.state.reqId}` )
        socket.off( `search:meta:${this.state.reqId}` )
        socket.off( `tail` );
        Array.from(( this.rowMap.keys() ) ).forEach( key => {
            socket.off( key );
        } );
    }

    async getData(): Promise<void> {
        const { socket, ids } = this.prepareSocketAndIds();
        this.clearRowMap();
        this.unbindAndAbort(socket, ids);
        this.removeSocketListeners(socket, ids);
    
        this.setState({ reqId: uuid(), loading: true }, () => {
            const filters = this.filtersToQuery();
            const sort = this.getSortObject();
    
            this.handleTailing(socket, filters, sort);
            this.emitSearch(socket, filters, sort);
        });
    }
    
    private prepareSocketAndIds() {
        const socket = this.userContext.gpSocket.project;
        const { rowData } = this.state;
        const ids = rowData.map(row => row._id);
        return { socket, ids };
    }
    
    private clearRowMap() {
        this.rowMap.clear();
    }
    
    private unbindAndAbort(socket: any, ids: string[]) {
        socket.emit("unbind:ids", ids);
        socket.emit("search:abort");
    }
    
    private removeSocketListeners(socket: any, ids: string[]) {
        ids.forEach(id => {
            socket.off(id);
        });
    }
    
    private getSortObject() {
        const { sortOrder, sortField } = this.state;
        return {
            [sortField ?? "importedTimestamp"]: sortOrder === "ascend" ? 1 : -1
        };
    }
    
    private handleTailing(socket: any, filters: any, sort: any) {
        if (
            sort.importedTimestamp === -1 &&
            Object.keys(filters).length === 0 ||
            (
                Object.keys(filters).length === 1 &&
                filters.importedTimestamp?.length > 1 &&
                new Date(filters.importedTimestamp[1]) > new Date()
            )
        ) {
            socket.emit("tail", () => console.info("tailing for new projects activated..."));
        } else {
            socket.emit("untail", () => console.info("tailing de-activated"));
        }
    }
    
    private emitSearch(socket: any, filters: any, sort: any) {
        const { currentPage, itemsPerPage, sortField, sortOrder } = this.state;
        socket.emit(`search`, {
            filter: filters,
            sort: {
                [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1
            },
            pagination: {
                pageNumber: currentPage || 1,
                resultsPerPage: itemsPerPage
            },
            reqId: this.state.reqId
        }, () => {
            this.setState({ loading: false, changedIds: new Set() });
        });
    }
    

    send(): Promise<void> {
        return Promise.resolve(undefined);
    }
}

export default function Invoicing () {
    const
        context = useMercuryContext(),
        location = useLocation(),
        breaks = useBreakpoint();

        
    return(
        <Routes location={location}>
            <Route
                path="/"
                //@ts-ignore
                element={<InvoicingSearch id={"Invoicing"} displayHideFilters={true} context={context} useLegacyEndpoints={false} breaks={breaks as any}/>}
            />
            <Route
                path="/invoicing-project/:id"
                element={<InvoicingDetailsPage id={"Invoicing"} key={location.key}/>}
            />
            <Route
                path='*'
                element={<NotFoundPage />}
            />
        </Routes>
    )
}
