import { Button, Form, Modal, Space } from "antd";
import { useMercuryContext } from "../../../user-context";
import Config from "../../../components/glms-front/helpers/config";
import { glms_stage } from "../../../utils/variables";
import { useEffect, useState } from "react";
import {
  failedNotification,
  successfulNotification,
} from "../../../utils/notifications";
import EntityCard from "./EntryCard";
import SessionStorage from "../../../Utilities/LocalStorage";
import EnumApi from "../../../components/glms-front/helpers/api";
import { fetchComponentTypes } from "../../../Search/ComponentType.filter";
import { fetchLanguageCodes } from "../../../Search/LanguageCode.filter";
import OverwriteSwitch from "./OverwriteSwitch";

export interface BulkEditLinguistsProps {
  title: string;
  disabled?: boolean;
  selectedRows: any;
  refreshList: () => void;
}

const componentType = new SessionStorage("componentType");
const sourceLanguage = new SessionStorage("sourceLanguage");
const programRole = new SessionStorage("programRole");
const projectRole = new SessionStorage("projectRole");
const product = new SessionStorage("product");
const productArea = new SessionStorage("productArea");

const BulkEditLinguists = ({
  title,
  disabled,
  selectedRows,
  refreshList,
}: BulkEditLinguistsProps) => {
  const config = new Config(glms_stage);
  const context = useMercuryContext();
  const { accessToken, gpClient } = useMercuryContext();
  const [products, setProducts] = useState<any>([]);
  const [componentTypes, setComponentTypes] = useState<any>([]);
  const [languages, setLanguages] = useState<any>([]);
  const [programRoles, setProgramRoles] = useState<any>([]);
  const [projectRoles, setProjectRoles] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allAreas, setAllAreas] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [overwriteActive, setOverwriteActive] = useState(false);
  const [overwriteEligible, setOverwriteEligible] = useState(false);
  const [overwriteShareNotifications, setOverwriteShareNotifications] =
    useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAndCacheData = async (
      data: any[],
      cache: { data?: any[] },
      fetchFunction: (context: any) => Promise<any[]>,
      setState: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      try {
        if (data.length === 0 && !cache?.data) {
          const result = await fetchFunction(context);
          setState(result);
          cache.data = result;
        } else {
          setState(cache.data || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchWithAuth = async (url: string) => {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error(`Error fetching ${url}`);
      return response.json();
    };

    const fetchProducts = async () => {
      try {
        const cachedProductArea = productArea.data;
        const cachedProducts = product.data;

        if (!cachedProductArea || !cachedProducts) {
          const productAreas = await fetchWithAuth(
            `${config.API}/${EnumApi.CoreGpms}/productArea`
          );
          const products = await fetchWithAuth(
            `${config.API}/${EnumApi.CoreGpms}/product`
          );

          const mappedProducts = products.map((product: any) => {
            const productArea = productAreas.find(
              (area: { _id: any }) => area._id === product.productArea
            );

            return {
              ...product,
              productArea: productArea
                ? { _id: productArea._id, title: productArea.title }
                : { _id: "emptyArea", title: "Others" },
            };
          });

          productArea.data = productAreas;
          product.data = mappedProducts;

          setProducts(mappedProducts);
        } else {
          setProducts(cachedProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchAndCacheData(
            componentTypes,
            componentType,
            fetchComponentTypes,
            setComponentTypes
          ),
          fetchAndCacheData(
            languages,
            sourceLanguage,
            fetchLanguageCodes,
            setLanguages
          ),
          fetchAndCacheData(
            programRoles,
            programRole,
            async () => await fetchWithAuth(`${config.URL}/glms/program/roles`),
            setProgramRoles
          ),
          fetchAndCacheData(
            projectRoles,
            projectRole,
            async () => await fetchWithAuth(`${config.URL}/glms/project/roles`),
            setProjectRoles
          ),
          fetchProducts(),
        ]);
      } catch (error) {
        console.error("Error during initial data fetching:", error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (products.length) {
      const areaKeys: any = {};
      for (const product of products) {
        areaKeys[product?.productArea?._id] = product?.productArea?.title;
      }
      const areas = [];
      for (const key in areaKeys) {
        areas.push({ value: key, label: areaKeys[key] });
      }
      setAllAreas(areas);
    }
  }, [products]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const generatePayload = (values: any) => {
    const payload: any = { changes: {} };

    const keys = Object.keys(values);

    keys.forEach((key) => {
      if (key.includes("_add")) {
        const field = key.split("_")[0];
        if (!payload.changes[field]) payload.changes[field] = {};

        payload.changes[field]["addRemove"] = {
          add: Array.from(new Set(values[`${field}_add`] || [])),
          remove: Array.from(new Set(values[`${field}_remove`] || [])),
        };
      }

      if (key.includes("_find")) {
        const field = key.split("_")[0];
        if (!payload.changes[field]) payload.changes[field] = {};

        const findValue = values[`${field}_find`];
        payload.changes[field]["findReplace"] = {
          find: Array.isArray(findValue)
            ? findValue
            : findValue
            ? [findValue]
            : [],
          replace: Array.from(new Set(values[`${field}_replace`] || [])),
        };
      }

      if (key.includes("_overwrite")) {
        const field = key.split("_")[0];
        if (!payload.changes[field]) payload.changes[field] = {};

        payload.changes[field]["overwrite"] = Array.from(
          new Set(values[`${field}_overwrite`] || [])
        );
      }
    });

    ["active", "eligible", "notifications"].forEach((key) => {
      if (key in values) {
        payload.changes[key] = values[key];
      }
    });

    return payload;
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const payload = generatePayload(values);
      payload.linguistIds = selectedRows?.map((el: any) => el?._id);
      setLoading(true);

      await gpClient.post(
        `/google-gp-v1/linguists/bulkUpdateLinguists`,
        payload
      );
      refreshList();
      const sesameIds = selectedRows?.map((el: any) => el?.sesameId);
      handleReset();
      setIsModalVisible(false);
      successfulNotification(
        `Sesame ID(s): ${sesameIds.slice(0, 2).join(", ")} have been updated ${
          sesameIds.length > 2 ? "... " + (sesameIds.length - 2) + " more" : ""
        }`,
        "This will be updated in real-time"
      );
    } catch (error: any) {
      if (error?.errorFields) {
        console.error("Validation failed:", error.errorFields);
      } else {
        failedNotification(
          "Error",
          "We've found an internal error saving data. Please, try again"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleReset = () => {
    form.resetFields();
    setOverwriteActive(false);
    setOverwriteEligible(false);
    setOverwriteShareNotifications(false);
  };

  const onProductAreasChange = (values: any) => {
    form.setFieldsValue({
      products: products
        .filter((x: any) => values.includes(x.productArea._id))
        .map((product: any) => product.title),
    });
  };

  return (
    <div>
      <Button type="primary" disabled={disabled} onClick={showModal}>
        {title}
      </Button>
      <Modal
        title={title}
        open={isModalVisible}
        className="linguist-modal"
        onCancel={handleCancel}
        width="80%"
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="reset" danger onClick={handleReset}>
            Reset
          </Button>,
          <Button
            disabled={loading}
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Update
          </Button>,
        ]}
      >
        <Form
          name="bulk-edit-linguists"
          form={form}
          layout="vertical"
          initialValues={{
            components_add: [],
            components_remove: [],
            components_find: null,
            components_replace: [],
            components_overwrite: [],
            products_add: [],
            products_remove: [],
            products_find: null,
            products_replace: [],
            products_overwrite: [],
            projectRoles_add: [],
            projectRoles_remove: [],
            projectRoles_find: null,
            projectRoles_replace: [],
            projectRoles_overwrite: [],
            languages_add: [],
            languages_remove: [],
            languages_find: null,
            languages_replace: [],
            languages_overwrite: [],
            sourceLanguage_add: [],
            sourceLanguage_remove: [],
            sourceLanguage_find: null,
            sourceLanguage_replace: [],
            sourceLanguage_overwrite: [],
            active: false,
            eligible: false,
            notifications: false,
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <EntityCard
              title="Components"
              options={componentTypes}
              findReplaceRequired
              formFieldsPrefix="components"
              placeholder="Component Types"
            />
            <EntityCard
              form={form}
              title="Products"
              options={products}
              findReplaceRequired
              formFieldsPrefix="products"
              placeholder="Products"
              showAreaField
              productAreas={allAreas}
              onProductAreasChange={onProductAreasChange}
            />
            <EntityCard
              title="Project Roles"
              options={projectRoles}
              findReplaceRequired
              formFieldsPrefix="projectRoles"
              placeholder="Project Roles"
            />
            <EntityCard
              title="Target Languages"
              options={languages}
              findReplaceRequired
              formFieldsPrefix="languages"
              placeholder="Target Languages"
            />
            <EntityCard
              title="Source Languages"
              options={languages}
              findReplaceRequired
              formFieldsPrefix="sourceLanguage"
              placeholder="Source Languages"
            />
            <OverwriteSwitch
              label="Active"
              overwriteChecked={overwriteActive}
              onOverwriteChange={setOverwriteActive}
              formItemName="active"
              formUncheckedText="Not Active"
              formCheckedText="Active"
            />

            <OverwriteSwitch
              label="Share Eligible"
              overwriteChecked={overwriteEligible}
              onOverwriteChange={setOverwriteEligible}
              formItemName="eligible"
              formUncheckedText="Not Eligible"
              formCheckedText="Eligible"
            />

            <OverwriteSwitch
              label="Receive Sharing Notifications"
              overwriteChecked={overwriteShareNotifications}
              onOverwriteChange={setOverwriteShareNotifications}
              formItemName="notifications"
              formUncheckedText="No Share Notifications"
              formCheckedText="Receive Share Notifications"
            />
          </Space>
        </Form>
      </Modal>
    </div>
  );
};

export default BulkEditLinguists;
