import React from "react";
import {

    TableAbstract,
    CustomTooltip, 
    TextFilter, 
    NumberFilter,
    DateFilter,
    BooleanFilter
} from "../../Components";
import {useMercuryContext} from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Tooltip } from "antd"

import TimezoneConverter from "../../timezone-converter";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ServiceFilter from "./Service.Filter";
import LanguageCodeFilter from "./LanguageCode.filter";

import "./ManageRates.scss"

import ManagePmfee from "./Manage.pmfee";
import CheckRateMatch from "./CheckRate.match";
import AddProjectRate from "./AddProjectRate";
import CopyProjectRate from "./CopyProjectRate";
import EditProjectRate from "./EditProjectRate";

class ManageRates extends TableAbstract<any> {
    defaultSortField = "name";
    FilterComponents = [
      {
          id: "name",
          name: "name",
          title: "Name",
          component: TextFilter,
          active: true
      },
      {
        id: "service",
        name: "service",
        title: "Service",
        component: ServiceFilter,
        active: true
      },
      {
        id: "sourceLangCode",
        name: "sourceLanguageCode",
        title: "Source Language",
        component: LanguageCodeFilter,
        active: true
    },
    {
        id: "targetLanguageCode",
        name: "targetLanguageCode",
        title: "Target Language",
        component: LanguageCodeFilter,
        active: true
    },
    {
      id: "wordRate",
      title: "Word Rate",
      name: "wordRate",
      component: NumberFilter,
      active: true
    },
    {
      id: "serviceRate",
      title: "Service Rate",
      name: "serviceRate",
      component: NumberFilter,
      active: true
    },
    {
      id: "dtpRate",
      title: "DTP Rate",
      name: "dtpRate",
      component: NumberFilter,
      active: true
    },
    {
      id: "engRate",
      title: "Engineering Rate",
      name: "engRate",
      component: NumberFilter,
      active: true
    },
    {
      id: "effectiveDate",
      title: "Effective Date",
      name: "effectiveDate",
      component: DateFilter
    },
    {
      id: "active",
      name: "active",
      title: "Active",
      component: BooleanFilter
  },
  ];
    columns = [
        {
            id: "name",
            title: "Name",
            dataIndex: "name",
            sorter: true,
            width: 250,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={30}/>
        },
        {   
            id:'service',
            title: "Service",
            dataIndex: "service",
            sorter: true,
            fixed: undefined,
            width: 220,
            render: (text: string) => <CustomTooltip text={text} length={25}/>
          },
          {
            id: "sourceLanguageCode",
            title: <Tooltip title="Source Language" placement="left" color={"var(--tertiary-color)"}>Source La...</Tooltip> as any,
            dataIndex: "sourceLanguageCode",
            sorter: true,
            fixed: undefined,
            width: 100,
            render: (text: string) => text
          },
          {
            id: "targetLanguageCode",
            title: <Tooltip title="Target Language" placement="left" color={"var(--tertiary-color)"}>Target La...</Tooltip> as any,
            dataIndex: "targetLanguageCode",
            sorter: true,
            fixed: undefined,
            width: 100,
            render: (text: string) => text
          },
          {
            id: "wordRate",
            title: "Word Rate",
            dataIndex: "wordRate",
            sorter: true,
            width: 100,
            fixed: undefined,
          },
          {
            id: "serviceRate",
            title: "Service Rate",
            dataIndex: "serviceRate",
            sorter: true,
            width: 110,
            fixed: undefined,
          },
          {
            id: "serviceUnit",
            title: "Service Unit",
            dataIndex: "serviceUnit",
            sorter: true,
            width: 110,
            fixed: undefined,
          },
          {
            id: "dtpRate",
            title: "DTP Rate",
            dataIndex: "dtpRate",
            sorter: true,
            width: 90,
            fixed: undefined,
          },
          {
            id: "dtpUnit",
            title: "DTP Unit",
            dataIndex: "dtpUnit",
            sorter: true,
            width: 90,
            fixed: undefined,
          },
          {
            id: "engRate",
            title: "Eng Rate",
            dataIndex: "engRate",
            sorter: true,
            width: 90,
            fixed: undefined,
          },
          {
            id: "engUnit",
            title: "Eng Unit",
            dataIndex: "engUnit",
            sorter: true,
            width: 90,
            fixed: undefined,
          },
          {
            id: "currency",
            title: "Currency",
            dataIndex: "currency",
            sorter: true,
            width: 90,
            fixed: undefined,
            render: (text: string) => <CustomTooltip text={text} length={90}/>
          },
          {
            id: "effectiveDate",
            title: "Effective Date",
            dataIndex: "effectiveDate",
            sorter: true,
            fixed: undefined,
            width: 160,
            render: (text:string) => <TimezoneConverter date={text}/>
          },
          {
            id:'active',
            title: "Active",
            dataIndex: "active",
            width: 75,
            sorter: true,
            fixed: undefined,
            render: (text:string, record:any) =>
            record.active ? (
                <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
              ) : (
                <CloseOutlined style={{ color: "var(--red)" }} />
              ),
          },
          {
            id: 'edit',
            title: "Edit",
            dataIndex: "edit",
            sorter: false,
            fixed: true,
            width: 100,
            render: (text:string, record:any) => <EditProjectRate currentRate={record} reload={this.getData}/>
          },
          {
            id: 'copy',
            title: "Copy",
            dataIndex: "copy",
            sorter: false,
            fixed: true,
            width: 70,
            render: (text:string, record:any) => {
                let active = record.active;
                if (record?.sourceLanguageCode?.code === "ALL" || record?.targetLanguageCode?.code === "ALL") {
                  active = false
                };
              return <CopyProjectRate currentRate={record} active={active} reload={this.getData}/>
            }
          },
    ];

    renderActions() {
      return <>
        <ManagePmfee fetchingData={this.state.loading}/>
        <CheckRateMatch fetchingData={this.state.loading}/>
        <AddProjectRate reload={this.getData} fetchingData={this.state.loading}/>
      </>
    }

    rowClassName(record: any, index: number): string {
        return 'rateRow'
    }

    async fetch() {

        const { sortOrder, sortField, currentPage, itemsPerPage } = this.state;
        
        const currencies = this.userContext.client.get("/core-cs-v1");
        const services = this.userContext.client.get("/core-gss-v1/service");
        const languages = this.userContext.gpClient.get("/google-gp-v1/languageCodes");

        const rates = this.userContext.client.get(`/core-grs-v1/rate/paginate/`, {
          params: {
            query: 
              this.filtersToQuery(),
              page: currentPage,
              limit: itemsPerPage,
              sort: {
                      [sortField ?? "created_at"]: sortOrder === "ascend" ? 1 : -1
                    } 
          }
        });

        const allData = await Promise.all([currencies, services, languages, rates]);

        let res:any = [];

        const currenciesData = allData[0],
        servicesData =  allData[1],
        languagesData = allData[2],
        ratesRes = allData[3],
        restesData = ratesRes.data[0];

        res = { data: { documents: restesData.documents.map(( d:any ) => {
          return {...d, 
            key: d._id, 
            service: servicesData.data.find(( service:any ) => service._id === d.service)?.name,
            currency: currenciesData.data.find(( currency:any ) => currency._id === d.currency)?.name,
            sourceLanguageCode: languagesData.data.find(( language:any ) => language._id === d.sourceLanguageCode)?.code,
            targetLanguageCode: languagesData.data.find(( language:any ) => language._id === d.targetLanguageCode)?.code,
          } 
          } ), meta: { ...restesData, page: restesData.page === 1 ? 1 : restesData.page - 1 } } } as any;
        
        return res;
    }

    pageTitle = "Manage Project Rates - Mercury";
    title = "Manage Project Rates";
    pageClass = "ratesTable"
}

export default function Rates() {
    const
        context = useMercuryContext(),
        breaks = useBreakpoint();

    return (<ManageRates id={"ManageRates"} noSelect={true} context={context as any} useLegacyEndpoints={false} breaks={breaks as any} hasSidebar={true}  />);
}
