import { DatePicker, Descriptions } from "antd";
import TimezoneConverter from "../../../timezone-converter";
import dayjs from "dayjs";
import { failedNotification } from "../../../utils/notifications";
import { useMercuryContext } from "../../../user-context";
import { useParams } from "react-router-dom";
import { useState } from "react";

const PlpGeneralInformation = (props: any) => {
  const { gpClient } = useMercuryContext();
  const [updating, setUpdating] = useState(false);
  const { plpId } = useParams();
  const { details, fetch } = props;

  const {
    product,
    sourceLanguageCode,
    latestReviewDueTimestamp,
    latestOriginalTranslationDueTimestamp,
    componentType,
    plpStatus,
    creationTimestamp,
    lastUpdatedTimestamp,
    turnaroundTimeUrgency,
    customVendorPLPMetadata,
  } = details;

  const {
    lastImportedTimestamp,
    lastSharedDate,
    projectType,
    varStatus,
    importedTimestamp,
    etaFromLxD,
  } = customVendorPLPMetadata || {};

  const update = async (payload: any) => {
    const data = {
      plpIds: [plpId],
      ...payload,
    };
    setUpdating(true);
    try {
      await gpClient.put("/google-gp-v1/plp/merge", data);
      fetch(setUpdating);
    } catch (error: any) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
  };

  return (
    <Descriptions bordered={true} column={2} size={"small"}>
      <Descriptions.Item label="Product">{product}</Descriptions.Item>
      <Descriptions.Item label="Source Language">
        {sourceLanguageCode}
      </Descriptions.Item>
      <Descriptions.Item label="Urgency">
        {turnaroundTimeUrgency}
      </Descriptions.Item>
      <Descriptions.Item label="Latest Translation Due Date">
        <TimezoneConverter date={latestOriginalTranslationDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Latest Review Date">
        <TimezoneConverter date={latestReviewDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Imported Timestamp">
        <TimezoneConverter date={lastImportedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Update Timestamp">
        <TimezoneConverter date={lastUpdatedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Component Type">
        {componentType}
      </Descriptions.Item>
      <Descriptions.Item label="Status">{plpStatus}</Descriptions.Item>
      <Descriptions.Item label="Received Date">
        <TimezoneConverter date={importedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Project Type">{projectType}</Descriptions.Item>
      <Descriptions.Item label="Last Shared Date">
        <TimezoneConverter date={lastSharedDate} />
      </Descriptions.Item>
      <Descriptions.Item label="Creation Timestamp">
        <TimezoneConverter date={creationTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="VAR Status">{varStatus}</Descriptions.Item>
      <Descriptions.Item label="ETA from LxD">
        <DatePicker
          picker="date"
          placeholder={"Select date"}
          disabled={updating}
          value={etaFromLxD ? dayjs(etaFromLxD) : undefined}
          onChange={async (v) => {
            update({
              customVendorPLPMetadata: {
                etaFromLxD: v,
              },
            });
          }}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default PlpGeneralInformation;
