import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import { DateTime } from "luxon";
import { Modal, Form, DatePicker, Space, Select, message, Input, Popconfirm, Button } from 'antd';
import { WarningOutlined } from "@ant-design/icons";
import bulkExtensionForm from '../../api/bulkExtensionForm';
import { JustificationCodes } from '../../constants/placeholders';
import {
  ADD_BUTTON,
  CANCEL_BUTTON,
  SELECT_DUE_DATE,
  SELECT_MESSAGE,
} from '../../constants/constants';
import { useMercuryContext } from '../../../../user-context';
import { failedNotification, successfulNotification } from "../../../../utils/notifications";
import { ProjectTooltip } from './ProjectTooltip';
import { getJustifications } from '../../api/justificationApi';
import { getLanguages } from '../../api/languageCodeApi';

export const BulkModal = ({
  apis,
  userDetails,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [justificationType, setJustificationType] = useState(null);
  const [justificationCode, setJustificationCode] = useState(0);
  const [requestedDueDate, setRequestedDueDate] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [justificationReasons, setJustificationReasons] = useState(null);
  const [languageOptions, setLanguageOptions] = useState(null);
  const [projectIds, setProjectIds] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productAreas, setProductAreas] = useState([]);
  const [languageCodes, setLanguageCodes] = useState([]);
  const [languageOnlyCodes, setLanguageOnlyCodes] = useState([]);
  const tokenSeparators = '/[ ,;]+/'.split('');
  const { TextArea } = Input;
  const { Option } = Select;
  const { accessToken, client } = useMercuryContext();
  const [popVisible, setPopVisible] = useState(false);

  // MER-1667 update
  const [form] = Form.useForm();

  const languagesCall = useCallback(async () => {
    try {
      const res = await getLanguages(accessToken);
      const codes = res.data.map((item) => {
        return {
          ...item,
          value: item.code,
        };
      });

      setLanguageCodes(codes);
      setLanguageOnlyCodes(
        codes.map((item) => {
          return item.value;
        })
      );
    } catch (err) {
      console.error(err);
    }
  },[accessToken]);

  useEffect(() => {
    languagesCall();
    const getProductType = async () => {
      const { productTypeEndpoint } = apis;
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        await axios
          .get(productTypeEndpoint, { method: 'GET', headers: headers })
          .then((result) => {
            setProductTypes(result.data);
          });
      } catch (error) {
        console.error('Product type error:' + error);
      }
    };
    getProductType();
    const getProductAreas = async () => {
      const { productAreaEndpoint } = apis;
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        await axios
          .get(productAreaEndpoint, { method: 'GET', headers: headers })
          .then((result) => {
            setProductAreas(result.data);
          });
      } catch (error) {
        console.error('Product areas error:' + error);
      }
    };
    getProductAreas();
    const apiCall = async () => {
      try {
        const res = await getJustifications(accessToken);
        // setJustificationReasons(res.data); // v1
        setJustificationReasons(res.data[0].documents);
      } catch (err) {
        console.error(err);
      }
    };
    apiCall();
  }, [accessToken, apis, languagesCall]);

  const showModal = async () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setLoading(false);
  };

  const postRejectHandler = (e) => {
    if (e) {
      e.forEach((currentValue) => {

        try {
          let extensionData = {
            projectId: currentValue.projectId,
            shortId: currentValue.shortId,
            sourceLanguageCode: currentValue.sourceLanguageCode,
            targetLanguageCode: currentValue.targetLanguageCode,
            originalDueDate: currentValue.originalDueDate,
            requestedDueDate: requestedDueDate,
            justificationCode: justificationCode,
            justification: justificationType.googleType,
            urgency: currentValue.urgency,
            requestedBy: currentValue.requestedBy,
            product: currentValue.product,
            productArea: currentValue.productArea,
            status: "Failed Validation",
            bulk: true,
            costCode: currentValue.costCode,
          };

          client.post(`/google-ges-v1/reject`, extensionData);

          // clearLPRows();
          form.resetFields();

        } catch (error) {
          console.error(error);
        }
      });
    }
  }

  const rejectHandler = (extensions) => {
    // prevent duplication of modal
    Modal.confirm({
      title: 'Do you want to record the failed request?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        postRejectHandler(extensions);
      },
      // onCancel: () => clearLPRows()
    });
  };

  const handleOk = () => {
    // Validations
    if (typeof justificationCode != 'number' || !justificationType || !requestedDueDate) {
      return message.error('Please validate all fields');
    }
    setLoading(true);

    let submittedBulkData = {
      requestedDueDate: requestedDueDate,
      justificationType: justificationType,
      justificationCode: justificationCode,
      caseId: caseId,
      projectIds: projectIds,
      languageOptions: languageOptions,
      apis: apis,
      userDetails: userDetails,
    }

    // Submitted bulk data for debugging
    // console.log(submittedBulkData)

    bulkExtensionForm(
      requestedDueDate,
      justificationType,
      justificationCode,
      setJustificationType,
      setJustificationCode,
      accessToken,
      setIsModalVisible,
      setLoading,
      setDisabled,
      caseId,
      setCaseId,
      setLanguageOptions,
      setProjectIds,
      projectIds,
      languageOptions,
      apis,
      productTypes,
      productAreas,
      userDetails,
      form,
      rejectHandler
    );
  };
  // for validation purposes
  const includesPilot = justificationType?.googleType.includes('Pilot');
  const includesL10n = justificationType?.googleType.includes('L10n');

  const handleSelection = (e) => {
    if (!e.$d) {
      setJustificationCode(e);
    } else {
      let dueDate = DateTime.fromISO(e.format('YYYY-MM-DDT13:59:59-00:00'))
      console.log('dueDate', dueDate.toISO())
      let dueDateToPST = dueDate.setZone("America/Los_Angeles")
      console.log('dueDateToPST', dueDateToPST.toISO())
      let dueDatePSTtimeSet = dueDateToPST.set({hour: 13, minutes: 59,  seconds: 59, milliseconds: 0 })
      console.log('dueDatePSTtimeSet', dueDatePSTtimeSet.toISO())
      let dueDateToUTC = dueDatePSTtimeSet.toUTC()
      console.log('dueDateToUTC', dueDateToUTC.toISO())
      let dueDateToISO = dueDateToUTC.toISO();
      console.log('dueDateToISO', dueDateToUTC.toISO())
      setRequestedDueDate(dueDateToISO);
    }
  };

  const handleJustification = (e) => {
    justificationReasons.filter((element) => {
      if (element.googleType === e) {
        return setJustificationType({
          kind: element.kind,
          limit: element.limit,
          googleType: element.googleType,
          frequency: element.frequency,
          description: element.description,
        });
      }
      return setJustificationType;
    });
  };

  function handleProjects(value) {
    const arr = value.map((element) => {
      return element.trim();
    });
    setProjectIds(arr);
  }
  function handleLanguage(value) {
    setLanguageOptions(value);
  }
  const handleAll = (value) => {
    if (value === 'ALL') {
      setLanguageOptions(languageOnlyCodes);
      form.setFieldsValue({
        'language-validation': languageOnlyCodes,
      });
    }
  };
  const handleDeselect = (value) => {
    if (value === 'ALL') {
      setLanguageOptions([]);
      form.setFieldsValue({
        'language-validation': [],
      });
    }
  };
  const disabledDate = (current) => {
    // Cannot select days before today
    return current && current < moment().startOf('day');
  };

  const handleCaseId = (e) => {
    setCaseId(e.target.value);
  };

  const caseIdPlaceholder = () => {
    if (includesL10n) return 'Insert Case Number';
    if (includesPilot) return 'Insert pilot/new product name';
    return null;
  };
  const caseIdLabel = () => {
    if (includesL10n) return 'Case Id Number';
    if (includesPilot) return 'Name of Pilot';
    return null;
  };

  const showExtPopconfirm = () => {
    setPopVisible(true);
  };

  const handleExtPopOk = () => {
    setPopVisible(false);
  };

  const handleExtPopCancel = () => {
    setPopVisible(false);
    showModal();
  };

  // const bulkFormValues = form.getFieldValue();

  // bulk extension form values before submitting for debugging
  // console.log(bulkFormValues)

  return (
    <>
      <Popconfirm
        open={popVisible}
        title="Has the project passed its launch date ?"
        onConfirm={handleExtPopOk}
        onCancel={handleExtPopCancel}
        okText="Yes"
        cancelText="No"
        okType="danger"
        icon={<WarningOutlined style={{ color: "var(--red)" }} />}
        cancelButtonProps={{ type: "ghost" }}
        okButtonProps={{ type: "primary" }}
        placement="top"
      >
        <Button type="primary" onClick={showExtPopconfirm} data-testid="button">
          Bulk request
        </Button>
      </Popconfirm>

      <Modal
        // forceRender
        destroyOnClose={true}
        title="Request Extension"
        maskClosable={false}
        open={isModalVisible}
        onCancel={() => handleCancel()}
        confirmLoading={loading}
        footer={null}
        data-testid="modal"
        // getContainer={false} fix for v2
      >
        <Form
          layout="vertical"
          style={{ width: "100%" }}
          autoComplete="off"
          initialValues={{ "array-validation": 0 }}
          form={form}
        >
          <Form.Item
            name="projects-validation"
            label={<ProjectTooltip />}
            rules={[
              {
                required: true,
                message: "Please insert valid project id",
              },
            ]}
          >
            <Select
              onChange={handleProjects}
              notFoundContent={false}
              disabled={loading}
              mode="tags"
              style={{ width: "100%", marginTop: "10px" }}
              placeholder="Type here to add more IDs"
              tokenSeparators={tokenSeparators}
            />
          </Form.Item>

          <Form.Item
            label="Target Language Code"
            name={["language-validation"]}
            rules={[
              {
                required: true,
                message: "Please insert valid project id",
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%", maxHeight: "120px", overflow: "auto" }}
              options={languageCodes}
              placeholder="Please select"
              onChange={handleLanguage}
              onSelect={handleAll}
              onDeselect={handleDeselect}
            />
          </Form.Item>
          <Form.Item
            name={["array-validation"]}
            label="Justification Code"
            rules={[
              {
                required: justificationCode === 0 ? false : true,
                message: SELECT_MESSAGE,
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select an option"
              data-testid="select"
              onChange={(e) => handleSelection(e)}
            >
              {JustificationCodes.map((option, index) => {
                return (
                  <Option value={option.value} key={index}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Justification"
            name="validation"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select an option"
              data-testid="select"
              onSelect={(e) => handleJustification(e)}
            >
              {justificationReasons
                ?.sort((a, b) => a.googleType.localeCompare(b.googleType)).map((option, index) => {
                  return (
                    <Option value={option.googleType} key={index}>
                      {option.googleType}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {justificationType && (
            <Form.Item>
              <TextArea
                showCount
                value={justificationType.description}
                disabled={true}
                autoSize={true}
                style={{
                  height: "100%",
                }}
              />
            </Form.Item>
          )}
          {(includesL10n || includesPilot) && (
            <Form.Item
              name="justification"
              label={caseIdLabel()}
              rules={[{ required: true }]}
            >
              <Input
                placeholder={caseIdPlaceholder()}
                allowClear
                onChange={(e) => handleCaseId(e)}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Requested Due"
            name="RequestedDue"
            rules={[{ required: true, message: SELECT_DUE_DATE }]}
          >
            <DatePicker
              showNow={false}
              disabledDate={disabledDate}
              onSelect={(e) => handleSelection(e)}
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                data-testid="modal-button-cancel"
                type="ghost"
                htmlType="button"
                onClick={() => handleCancel()}
              >
                {CANCEL_BUTTON}
              </Button>
              <Button
                data-testid="modal-button-add"
                disabled={disabled}
                block={true}
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => handleOk()}
              >
                {ADD_BUTTON}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
