import React from "react";
import { Row, Col, Space, Switch, Form } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const OverwriteSwitch = ({
  label,
  overwriteChecked,
  onOverwriteChange,
  formItemName,
  formUncheckedText,
  formCheckedText,
  defaultChecked = true,
}: any) => {
  return (
    <Row gutter={8}>
      <Col span={4}>{label}</Col>
      <Col span={20}>
        <Space style={{ width: "100%" }}>
          <Switch
            unCheckedChildren={
              <Space>
                No Changes
                <CloseOutlined />
              </Space>
            }
            checkedChildren={
              <Space>
                <CheckOutlined />
                Overwrite All
              </Space>
            }
            checked={overwriteChecked}
            onChange={onOverwriteChange}
          />
          {overwriteChecked && (
            <Form.Item name={formItemName} valuePropName="checked" noStyle>
              <Switch
                unCheckedChildren={
                  <Space>
                    {formUncheckedText}
                    <CloseOutlined />
                  </Space>
                }
                checkedChildren={
                  <Space>
                    <CheckOutlined />
                    {formCheckedText}
                  </Space>
                }
                defaultChecked={defaultChecked}
              />
            </Form.Item>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default OverwriteSwitch;
