import SearchProjectAutocomplete from "./SearchProjectAutocomplete";
import Profile from "../../auth/Profile";

interface RightMenuProps {
  adminMenuData: {
    handleMainMenuSelection: (menu: string) => void;
  };
  drawOpen?: boolean;
}

const RightMenu: React.FC<RightMenuProps> = ({ adminMenuData }) => {
  return (
    <div className="right-menu-container">
      <SearchProjectAutocomplete
        handleMainMenuSelection={adminMenuData.handleMainMenuSelection}
      />
      <Profile
        adminData={adminMenuData}
        handleMainMenuSelection={adminMenuData.handleMainMenuSelection}
      />
    </div>
  );
};

export default RightMenu;
