import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import logo from "../assets/images/RWS_logo-colour.svg";
import "./not-found-page.scss";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const returnHome = (): void => {
    navigate("/");
  };

  return (
    <div className="not-found-page">
      <Helmet>
        <title>Page not found (404) - Mercury © RWS</title>
      </Helmet>

      <div className="container">
        <div className="logo">
          <img src={logo} alt="logo" />
          <h1>Mercury</h1>
        </div>
        <div className="intro">
          <h1>404 - Page not found</h1>
          <h3>
            The page you are looking for might have been removed or is
            temporarily unavailable.
          </h3>
        </div>
        <Button
          type="link"
          className="login-btn"
          icon={<HomeOutlined />}
          onClick={returnHome}
        >
          Return home
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
