import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMercuryContext } from "../user-context";
import axios from "axios";
import { Helmet } from "react-helmet";

import { Form } from "antd";
import { API_URL, BASE_URL } from "../utils/variables";
import { defaultHeaders } from "../api/APIUtils";

import PageTitle from "../ui/PageTitle";
import AddHelixTemplateMap from "../components/managePages/helix-template-map/AddHelixTemplateMap";
import EditHelixTemplateMap from "../components/managePages/helix-template-map/EditHelixTemplateMap";
import DeleteHelixTemplateMap from "../components/managePages/helix-template-map/DeleteHelixTemplateMap";
import HelixTemplateMapTable from "../components/managePages/helix-template-map/HelixTemplateMapTable";
import BackTopButton from "../ui/BackTopButton";
import LoadingStar from "../ui/LoadingStar";

import {
  failedNotification,
  successfulNotification,
  infoNotification,
} from "../utils/notifications";

const initialEditingValue = {
  isEditing: false,
  editingRow: "",
  isDeleting: false,
};

const ManageHelixTemplateMap = () => {
  const { accessToken, gpClient } = useMercuryContext();
  const editFormRef = useRef(null);
  const [form] = Form.useForm();
  const [editingForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [helixTemplateMaps, setHelixTemplateMaps] = useState([]);
  const [helixTemplates, setHelixTemplates] = useState([]);
  const [components, setComponents] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [sortedInfo, setSortedInfo] = useState("");
  const [editing, setEditing] = useState(initialEditingValue);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(0);

  const mapsEndpoint = `${API_URL}/google-ghtms-v1`;
  const servicesEndpoint = `${API_URL}/core-gss-v1/service`;
  const productsEndpoint = `${API_URL}/core-gpms-v1/product`;
  const helixTemplateEndpoint = `${API_URL}/core-htms-v1?page_size=${total}&skip=0`;

  const fetchHelixTemplateMapList = useCallback(
    async (token, useIsLoading = true) => {
      try {
        setIsLoading(useIsLoading);

        let headers = {
          headers: defaultHeaders(token),
        };

        const requestMaps = axios.get(mapsEndpoint, headers);
        const requestServices = axios.get(servicesEndpoint, headers);
        const requestProducts = axios.get(productsEndpoint, headers);
        const requestHelixTemplate = axios.get(helixTemplateEndpoint, headers);

        await axios
          .all([
            requestMaps,
            requestServices,
            requestProducts,
            requestHelixTemplate,
          ])
          .then(
            axios.spread((...responses) => {
              const responseMaps = responses[0];
              const responseServices = responses[1];
              const responseProducts = responses[2];
              const responseHelixTemplates = responses[3];

              const sortedData = responseMaps.data.sort(
                (a, b) => a.order - b.order
              );

              setHelixTemplateMaps(
                sortedData.map((row, index) => ({
                  key: row?.order,
                  ...row,
                }))
              );

              setTotal(responseMaps.data.length);
              setServices(responseServices.data);
              setProducts(responseProducts.data);
              setHelixTemplates(responseHelixTemplates.data);
              setOrder(total && total - 1);
            })
          )
          .catch((err) => console.error('Error fetching helix template map list: ', err));
      } catch (error) {
        failedNotification("Error Fetching Api", error);
      }
      setIsLoading(false);
    },
    [
      helixTemplateEndpoint,
      mapsEndpoint,
      productsEndpoint,
      servicesEndpoint,
      total,
    ]
  );

  // on load
  useEffect(() => {
    accessToken && fetchHelixTemplateMapList(accessToken);
  }, [accessToken, fetchHelixTemplateMapList]);

  useEffect(async () => {
    const { data } = await gpClient.get(
      "/google-gp-v1/project/listFields/componentType"
    );

    setComponents(data.map((item) => item.value));

  }, [gpClient])
  

  // add new helix template map
  const addHelixTemplateMap = async (token, body) => {
    const endpoint = `${API_URL}/google-ghtms-v1`;
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        method: "post",
        url: endpoint,
        headers: defaultHeaders(token),
        data: body,
      }).then((result) => {
        if (result.data.error) {
          failedNotification(result.data.error, result.data.message);
        } else {
          let helixTemplateObj = helixTemplates.find(
            (template) => template._id === result.data.helixTemplateId
          );
          successfulNotification(
            `Helix template ${helixTemplateObj.name} was added.`,
            "The page will be reloaded. Please refresh your page if the update is not visible."
          );
        }
      });
    } catch (error) {
      console.error('Error adding Helix template map: ', error);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // update a single helix template map
  const updateHelixTemplateMap = async (token, newData) => {
    const id = editing.editingRow.id;
    const endpoint = `${API_URL}/google-ghtms-v1/${id}`;
    try {
      setEditing({ ...editing, isEditing: true, isDeleting: false });
      await axios({
        method: "patch",
        url: endpoint,
        headers: defaultHeaders(token),
        data: newData,
      }).then((result) => {
        let helixTemplateObj = helixTemplates.find(
          (template) => template._id === result.data.helixTemplateId
        );
        successfulNotification(
          `Helix template ${helixTemplateObj.name} was updated.`,
          `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    handleRefresh();
  };

  // batch update helix template maps
  const batchUpdateTemplateMaps = async (newData) => {
    const endpoint = `${API_URL}/google-ghtms-v1/batch`;
    const batchPutData = newData.map((row, index) => {
      return {
        id: row?.id,
        services: row?.services,
        products: row?.products,
        components: row?.components,
        helixTemplateId: row?.helixTemplateId,
        order: row?.order,
      };
    });

    try {
      await axios({
        method: "put",
        url: endpoint,
        headers: defaultHeaders(accessToken),
        data: batchPutData,
      })
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
  };

  const handleFinishDelete = async (submitted) => {
    const id = editing.editingRow.id;
    const endpoint = `${API_URL}/google-ghtms-v1/${id}`;
    try {
      // Check if form was submitted or cancelled
      if (!submitted) {
        setEditing(initialEditingValue);
        return;
      }
      setEditing({ ...editing, isEditing: true, isDeleting: true });
      await axios({
        method: "delete",
        url: endpoint,
        headers: defaultHeaders(accessToken),
      }).then((result) => {
        successfulNotification(
          `The Google Helix template map was deleted.`,
          `The page will be reloaded. Please refresh your page if the update is not visible.`
        );
      });
    } catch (error) {
      failedNotification(error.response.data.error, error.response.data.detail);
    }
    setEditing(initialEditingValue);
    quietRefresh();
  };

  const defaultValues = {
    idValue: editing.editingRow.id,
    servicesValue: editing.editingRow.services,
    productsValue: editing.editingRow.products,
    componentsValue: editing.editingRow.components,
    helixTemplateIdValue: editing.editingRow.helixTemplateId,
    orderValue: editing.editingRow.key,
  };

  // fix to update initial values of edit form
  useEffect(() => {
    if (editFormRef.current) {
      editingForm.setFieldsValue(defaultValues);
    }
  }, [editFormRef, editingForm, defaultValues]);

  // refresh button
  const handleRefresh = () => {
    //fetch details again
    accessToken && fetchHelixTemplateMapList(accessToken, true);
  };

  // to prevent deletion memory leak
  const quietRefresh = async () => {
    try {
      await fetchHelixTemplateMapList(accessToken, false);
      infoNotification(
        "Google Helix Template Mappings data reloaded",
        "The Helix template data has been reloaded"
      );
    } catch (error) {
      failedNotification(
        "Failed to reload",
        "Unable to reload the Google Helix Template Mappings data at this time, please try again. If the issue persists please contact support."
      );
    }
  };

  // Add new helix map template START
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    form.resetFields();
  };

  // on save of add new template map
  const onFinishNewTemplateMap = (values) => {
    // generates the new template object
    const newValues = {
      services: values.servicesValue,
      products: values.productsValue,
      components: values.componentsValue,
      helixTemplateId: values.helixTemplateIdValue,
      order: values.orderValue,
    };

    addHelixTemplateMap(accessToken, newValues);
    closeDrawer();
  };
  // Add new helix template END

  // Update helix template START

  function showEditDrawer() {
    setVisibleEditDrawer(true);
  }

  const closeEditDrawer = (event) => {
    setVisibleEditDrawer(false);
    form.resetFields();
    event !== undefined && setEditing(initialEditingValue);
  };

  // on save of edit helix template mapping
  const onFinishEditing = (values) => {
    const {
      idValue,
      servicesValue,
      productsValue,
      componentsValue,
      helixTemplateIdValue,
    } = values;

    const newData = {
      id: idValue,
      services: servicesValue,
      products: productsValue,
      components: componentsValue,
      helixTemplateId: helixTemplateIdValue,
      order: editing.editingRow.key,
    };

    updateHelixTemplateMap(accessToken, newData);

    closeEditDrawer();
  };
  // Update helix template END

  // handle change table
  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  // handle click table edit button
  const handleClickEdit = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: false,
    });
    showEditDrawer();
  };

  // Delete button
  const handleClickDelete = (record) => {
    setEditing({
      ...editing,
      isEditing: true,
      editingRow: record,
      isDeleting: true,
    });
  };

  return (
    <div className="manage-page manage-helix-template-map-page" style={{padding: '0 10px'}}>
      <Helmet>
        <title>Manage Google Helix Template Mappings - Mercury © RWS</title>
      </Helmet>
      {isLoading ? (
        <LoadingStar />
      ) : (
        <>
          <PageTitle title="Manage Google Helix Template Mappings" />
          <AddHelixTemplateMap
            handlers={{
              showDrawer,
              closeDrawer,
              onFinishNewTemplateMap,
              handleRefresh,
            }}
            values={{
              form,
              visibleDrawer,
              helixTemplates,
              components,
              products,
              services,
              order,
            }}
          />
          <EditHelixTemplateMap
            handlers={{
              closeEditDrawer,
              onFinishEditing,
            }}
            values={{
              editingForm,
              visibleEditDrawer,
              editing,
              editFormRef,
              helixTemplates,
              components,
              products,
              services,
            }}
          />
          <DeleteHelixTemplateMap
            visible={editing.isDeleting}
            onSubmit={handleFinishDelete}
            selectedTemplateMap={editing.editingRow}
          />
          <HelixTemplateMapTable
            values={{
              helixTemplateMaps,
              helixTemplates,
              sortedInfo,
              editing,
              total,
            }}
            handlers={{
              handleChangeTable,
              handleClickEdit,
              handleClickDelete,
              handleFinishDelete,
              batchUpdateTemplateMaps,
              handleRefresh
            }}
          />
          <BackTopButton />
        </>
      )}
    </div>
  );
};

export default ManageHelixTemplateMap;
