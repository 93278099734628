import { CopyOutlined } from "@ant-design/icons";
import { Descriptions, Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { successfulNotification } from "../../../utils/notifications";

const QuestionsAndAnswers = ({ questionAnswerInstructions = [] }: any) => {
  const hasInstructions = questionAnswerInstructions?.length > 0;

  const textToCopy = questionAnswerInstructions
    ?.map((item: any) => `${item.question}: ${item.answer}`)
    .join("\n");

  const renderGridContent = () =>
    questionAnswerInstructions?.map((item: any, index: number) => (
      <div key={index} className="qa-item">
        <div className="qa-question">{item.question}</div>
        <div className="qa-answer">{item.answer}</div>
      </div>
    ));

  return (
    <Descriptions
      bordered
      size="small"
      column={3}
      title="Questions & Answers Instructions:"
      className="qa-descriptions"
      extra={
        hasInstructions && (
          <Tooltip
            title="Copy to Clipboard"
            color="var(--tertiary-color)"
            placement="left"
          >
            <CopyToClipboard text={textToCopy}>
              <CopyOutlined
                style={{
                  color: "var(--tertiary-color)",
                  paddingRight: "5px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  successfulNotification(
                    "Copied successfully",
                    "All Questions & Answers Instructions were copied to your clipboard."
                  )
                }
              />
            </CopyToClipboard>
          </Tooltip>
        )
      }
    >
      {hasInstructions ? (
        <Descriptions.Item>
          <div className="qa-grid">{renderGridContent()}</div>
        </Descriptions.Item>
      ) : (
        <div className="qa-empty">No questions or answers available.</div>
      )}
    </Descriptions>
  );
};

export default QuestionsAndAnswers;
