import { ListProps } from "../../Components";
import { FiniteListWithAsyncLoaderAbstract } from "../../stories/FiniteListFilter/FiniteListWithAyncLoader.abstract";
import { useMercuryContext } from "../../user-context";

export const fetchProgramRole = async (context: any) => {
    
    const { data } = await context.gpClient.get(
      "/google-gp-v1/linguists/listFields/programRoles"
    );
    
    return data.map((item: any) => ({
      title: item.label,
      value: item.value,
    }));
  };
  
  class ProgramRoleFilter extends FiniteListWithAsyncLoaderAbstract {
    fetch() {
      return fetchProgramRole(this.props.context);
    }
  }
  
  export default (props: ListProps) => {
    const context = useMercuryContext();
    return <ProgramRoleFilter context={context} {...props} />;
  };