import { EXTENSIONS_API } from "../../../utils/variables";

export const DUPLICATES = () => `${process.env.REACT_APP_API_URL}/google-ges-v1/duplicates`;

// get justification codes that represent the justification type
export const JUSTIFICATIONS_API = () => `${process.env.REACT_APP_API_URL}/google-ges-v1/justification/paginate?page=1&limit=500`;

export const BASE_PATH = () => EXTENSIONS_API;

export const API_VERSION = "v1";
