import React from "react";
import { Row, Col, Typography } from "antd";
import logo from "../assets/images/loading.gif";

interface MessageProps {
  first: string;
  second: string;
  loading?: boolean;
}

const Message: React.FC<MessageProps> = ({ first, second, loading = true }) => {
  const { Title } = Typography;
  return (
    <Row justify="center" style={{ padding: "50px 0" }}>
      <Col span={8}>
        {loading && <img src={logo} className="logo" alt="logo" />}
        <Title level={2} style={{ fontWeight: "400" }}>
          {first}
        </Title>
        <Title level={5} style={{ fontWeight: "400" }}>
          {second}
        </Title>
      </Col>
    </Row>
  );
};

export default Message;
